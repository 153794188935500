/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavigationComponent from "../../components/NavigationComponent";
import TopNavBarComponent from "../../components/TopNavBarComponent";
import WarningBanner from "../../components/WarningBanner";
import axios from "../../../api/services/Api";
import {
  removeToken,
  removeUserData,
  removeExpiredTime,
  removeRefreshToken,
} from "../../../api/services/Auth";
import {
  logoutStart,
  logoutFinish,
  setToken,
  setExpiredTime,
  signIn,
  setTokenInHeaders,
} from "../../../api/actionCreators/authActionCreator";
import { saveMerchantBankData } from "../../../api/actionCreators/mainActionCreator";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { IAppMainLayoutProps } from "./types";
import classNames from "classnames";
import { getRefreshToken } from "../../../api/selectors/tokenSelector";
import { getMerchantBankData } from "../../../api/selectors/mainSelector";
import styles from "./app-main-layout.module.scss";

function AppMainLayout({
  navBarTitle,
  children,
  noBackground,
  className,
  breadcrumbs,
}: IAppMainLayoutProps) {
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refreshToken = useSelector(getRefreshToken);
  const merchantData = useSelector(getMerchantBankData);
  
  const getSettings = () => {
    axios
      .get(
        `/cap/api/v1/bank`
      )
      .then((res) => {
        const { data } = res;
        dispatch(saveMerchantBankData({
          bankName: data.bankInfo.bankName,
          imgSrc: `data:image/jpeg;base64,${data.bankInfo.image}`
        }))
      })
  }

  useEffect(() => {
    merchantData.bankName.length === 0 && getSettings()
  }, [merchantData])

  // console.log("refreshToken", refreshToken)
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     token &&
  //       axios.get("/state").then((res) => {
  //         const {
  //           data: { isMaintenanceMode, isMarketActive },
  //         } = res;
  //         if (isMaintenanceMode) {
  //           setMode(true);
  //           dispatch(setModeStatus(true));
  //           setModeMessage(t("Market_close_total"));
  //           setModeDialogDisabled(false);
  //         } else if (!isMarketActive && !isMaintenanceMode) {
  //           setMode(true);
  //           dispatch(setModeStatus(true));
  //           setModeMessage(t("Market_close_by"));
  //           setModeDialogDisabled(true);
  //         } else {
  //           setMode(false);
  //           dispatch(setModeStatus(false));
  //         }
  //       });
  //   }, 3000);
  //   // Clean up the interval on component unmount

  //   token &&
  //     axios.get("/state").then((res) => {
  //       const {
  //         data: { isMaintenanceMode, isMarketActive },
  //       } = res;
  //       if (isMaintenanceMode) {
  //         setMode(true);
  //         dispatch(setModeStatus(true));
  //         setModeMessage(t("Market_close_total"));
  //         setModeDialogDisabled(false);
  //       } else if (!isMarketActive && !isMaintenanceMode) {
  //         setMode(true);
  //         dispatch(setModeStatus(true));
  //         setModeMessage(t("Market_close_by"));
  //         setModeDialogDisabled(true);
  //       } else {
  //         setMode(false);
  //         dispatch(setModeStatus(false));
  //       }
  //     });

  //   return () => clearInterval(intervalId);
  // }, []);
  // const { t } = useTranslation();
  // const token = useSelector(getToken)
  // const accessToken = useSelector(getAccessToken)
  // const rToken = useSelector(getRefreshToken)
  // const settings = useSelector(getSettingsState);
  // const expiredTime = useSelector(getExpiredTime);
  // const isReloadPage = useSelector(getReloadPageState);
  // const [expiredSecond, setExpiredSecond] = useState(SEC_BY_DEFAULT_HALF);

  // const runRefresh =
  //   expiredSecond <= SEC_FOR_TIMEOUT

  // useEffect(() => {
  //   !token && !accessToken && setRedirect(true);
  // }, [token]);

  // const checkTimeToRefreshToken = () => {
  //   const now = moment();
  //   const secDiff = now.diff(expiredTime, "seconds");
  //   setExpiredSecond(secDiff * -1);
  // };

  // const refreshToken = () => {
  //   //setRefresh(true)
  //   const params = {
  //     refreshToken: rToken,
  //   }

  //   axios.post("/refreshToken", { ...params })
  //     .then((res: any) => {

  //       if (res.status === 200) {
  //         const { data: { accessToken, expiresIn, refreshToken } } = res
  //         const tokenData = {
  //           accessToken,
  //           expiresIn,
  //           refreshToken,
  //         }
  //         storeToken(accessToken)
  //         storeRefreshToken(refreshToken)
  //         const updatedDate = moment().add(expiresIn, 'seconds');
  //         storeExpiredTime(updatedDate)
  //         dispatch(setToken(tokenData))
  //         dispatch(setExpiredTime(updatedDate))
  //         // setRefresh(false)
  //         //setExpiredSecond(SEC_BY_DEFAULT_HALF)
  //       } else if (!res.status) {
  //         enqueueSnackbar(t('Session_expired'))
  //         onExit()
  //       }
  //     })
  // }

  // useEffect(() => {
  //   console.log(">", expiredSecond)
  //   if (expiredSecond < 0) {
  //     refreshToken()
  //   } else if (runRefresh) {
  //     //setExpiredSecond(SEC_BY_DEFAULT)
  //     accessToken && refreshToken()
  //   }
  //   const id = setInterval(checkTimeToRefreshToken, 3000);
  //   return () => clearInterval(id);
  // }, [expiredSecond]);

  const clearData = () => {
    removeExpiredTime();
    removeRefreshToken();
    removeToken();
    removeUserData();

    dispatch(logoutFinish());
    dispatch(setToken({ accessToken: "", refreshToken: "", expiresIn: "" }));
    dispatch(setExpiredTime(null));
    dispatch(signIn(null));

    dispatch(setTokenInHeaders(false));
    setRedirect(true);
  };

  const onExit = () => {
    dispatch(logoutStart());
    axios.post("/cap/api/v1/auth/logout", { refreshToken }).then(() => {
      clearData();
    });
  };

  return (
    <>
      {redirect && <Redirect to="/" />}
      <div
        className={classNames({
          [`${styles.main_section}`]: true,
          [`${className}`]: true,
        })}
      >
        <div className={styles.main_grid}>
          <NavigationComponent />
          <div>
            <TopNavBarComponent {...{ breadcrumbs, navBarTitle, onExit }} />
            <Box>
              <Paper
                elevation={1}
                className={classNames({
                  [`${styles.w100}`]: true,
                  [`${styles.background_none}`]: noBackground,
                })}
              >
                {children}
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppMainLayout;
